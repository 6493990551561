import React, { useContext, useEffect, useState } from "react";
import { FormModal } from "../Shared/form";
import { FormContext, FormSchemaFields, ToggleSize } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import inspectionIntervalsApi from "../../utils/api/inspectionIntervals";

function getSchema(conditionalFormSchemaProperty:any) {
  return {
    name: {
      label: 'Name',
      autoComplete: 'organization1',
      validation: {
        required: true,
      },
    },
    interval: {
      label: 'Interval',
      controlType: UIControlType.number,
      placeholder: '',
      autoComplete: 'address-line1',
      'aria-label': 'Street address line 1 of 2',
      validation: {
        required: true,
      },
    },
    code: {
      label: "Code",
      autoComplete: 'code',
      validation: {
        required: false,
      },
    },
    exclude_weekends: {
      label: "Exclude Weekends",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm' as ToggleSize
    },
    exclude_us_holidays: {
      label: "Exclude US Holidays",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm' as ToggleSize
    },
    ...conditionalFormSchemaProperty
  };
}

const IntervalModal = (props: any) => {
  const { setshowIntervalModal, isEditAction, selectedIntervalDetails, inspectionIntervalsQuery } = props
  const formContext = useContext(FormContext);
  const [createResponse, setCreateResponse] = useState({})
  const [editResponse, setEditResponse] = useState({})
  const [deleteResponse, setDeleteResponse] = useState({})
  const [formDataClone, setFormDataClone] = useState(formContext.value)

  const handleCreate = async(formData:any) => {
    try{
      const createResponsedata = await inspectionIntervalsApi.post(`/inspection-intervals`, {data: formData})
      
      setEditResponse(createResponsedata)
      setshowIntervalModal(false)
    }
    catch(error){
      console.log(error)
    }
  }

  const handleEdit = async(formData:any) => {
    try{
      const editResponsedata = await inspectionIntervalsApi.update(`/inspection-intervals/${selectedIntervalDetails.id}`, {data: formData})

      await inspectionIntervalsApi.updateNextInspectionDateForIntervalId(selectedIntervalDetails.id)

      setCreateResponse(editResponsedata)
      setshowIntervalModal(false)
    }
    catch(error){
      console.log(error)
    }
  }

  const handleDelete = async() => {
    try{
      const deletedResponsedata = await inspectionIntervalsApi.delete(`/inspection-intervals/${selectedIntervalDetails.id}`)

      setDeleteResponse(deletedResponsedata)
      setshowIntervalModal(false)
    }
    catch(error){
      console.log(error)
    }
  }

  const conditionalFormSchemaProperty = (formDataClone.exclude_weekends || formDataClone.exclude_us_holidays) ? 
  {
    before_after: {
      label: 'Next inspection to be scheduled',
      controlType: UIControlType.select,
      disabled: (formDataClone.exclude_weekends || formDataClone.exclude_us_holidays) ? false : true,
      options: [{value: "BEFORE"}, {value: "AFTER"}],
      labelKey: 'value',
      valueKey: 'value'
    },
  } : {}

  useEffect(() => {
    inspectionIntervalsQuery.refetch()
  },[createResponse, editResponse, deleteResponse])

  useEffect(() => {
    setFormDataClone(formContext.value)
  },[formContext.value])

  return (
    <FormModal
      modalProps={{
        title: `${isEditAction ? 'Edit Interval' : 'Create Interval'}`,
        submitBtnText: "Submit",
      }}
      onSubmit={(formData) => isEditAction ? handleEdit(formData) : handleCreate(formData)}
      onCancel={() => setshowIntervalModal(false)}
      showDelete={isEditAction ? true : false}
      onDelete={() => handleDelete()}
    >
      <FormSchemaFields
        schema={getSchema(conditionalFormSchemaProperty)}
        onChange={formContext.set}
        formData={formContext.value}
        initialFormData={isEditAction ? selectedIntervalDetails : {}}
      />
    </FormModal>
  );
};

export default IntervalModal;
