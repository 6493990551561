import React, { useContext } from 'react';
import ResourceDetailTabs from '../Shared/ResourceDetail/ResourceDetailTabs';
import { FormContextProvider } from '@sw-sw/lib-form';
import { SettingsTab } from './SettingTabs';
import InspectionIntervals from './InspectionIntervals';
import { PrecipitationTracking } from './Features/PrecipitationTracking';
import { SiteMapEditor } from './Features/SitemapEditor';
import { PDFEditing } from './Features/PDFEditing';
import { Reporting } from './Features/Reporting';
import { RolesContext } from '../../contexts/RolesContext';
import { ProjectTypes } from "./ProjectType";
import { RegionComponent } from "./Region"

export default function Administration() {
  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanEdit = permCheck('all', 'Administration Features');

  return (
    <>
      {userCanEdit ? (
        <ResourceDetailTabs
          headers={['Settings', 'Features']}
          panels={[
            () => (
              <FormContextProvider>
                <SettingsTab />
                <InspectionIntervals />
                <ProjectTypes />
                <RegionComponent />
              </FormContextProvider>
            ),
            () => (
              <FormContextProvider>
                <PrecipitationTracking />
                <PDFEditing />
                <SiteMapEditor />
                <Reporting />
              </FormContextProvider>
            ),
          ]}
          showTab={true}
        />
      ) : (
        <ResourceDetailTabs
          headers={['Settings']}
          panels={[
            () => (
              <FormContextProvider>
                <SettingsTab />
                <InspectionIntervals />
                <ProjectTypes />
                <RegionComponent />
              </FormContextProvider>
            ),
          ]}
          showTab={true}
        />
      )}
    </>
  );
}
