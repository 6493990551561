import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../contexts/AppContext';
import RolesContext from '../../../contexts/RolesContext';
import ToggleSmall from '../../Shared/Toggle/ToggleSmall';
import tenantApi from '../../../utils/api/tenant';
import { toast } from 'react-toastify';

interface ReportingFormat {
  feature: {
    feature_type: string;
    name: string;
  };
  id: any;
  is_enabled: boolean;
}

export const Reporting = () => {
  const [collapse, setCollapse] = useState(false);
  const [reporting, setReporting] = useState<ReportingFormat[]>([]);
  const appContext = useContext(AppContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const userTenantId =
    appContext &&
    appContext.state &&
    appContext.state.user &&
    appContext.state.user.tenantId;

  const loadInitialData = () => {
    tenantApi.features.index(userTenantId, 'reporting').then((res) => {
      setReporting(res);
    });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleToggle = async (id: number, currentInclude: boolean) => {
    const updatedReporting = reporting.map((ele) => {
      if (ele.id === id) {
        return {
          feature: {
            feature_type: ele.feature.feature_type,
            name: ele.feature.name,
          },
          id: id,
          is_enabled: !currentInclude,
        };
      }

      return ele;
    });

    setReporting(updatedReporting);
    tenantApi.features
      .update(userTenantId, id, !currentInclude)
      .then(() => {})
      .catch(() => {
        toast.error('Error updating feature');

        const errorhandledReporting = reporting.map(
          (ele) => {
            if (ele.id === id) {
              return {
                feature: {
                  feature_type: ele.feature.feature_type,
                  name: ele.feature.name,
                },
                id: id,
                is_enabled: currentInclude,
              };
            }

            return ele;
          },
        );

        setReporting(errorhandledReporting);
      });
  };

  return (
    <div className='settings-tenant-main-container'>
      <div
        className='settings-main-tenant-text'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '30px',
          cursor: 'pointer',
        }}
        onClick={() => setCollapse(!collapse)}
      >
        <h3>Reporting</h3>
        <i
          className={collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
          aria-hidden='true'
        />
      </div>
      <hr></hr>
      <div
        className={
          collapse ? 'administration-hidden' : 'administration-visible'
        }
      >
        {reporting.map((ele) => {
          return (
            <div className='features-table-body'>
              <h3>{ele.feature.name}</h3>
              <h3 className='features-table-body-toggle'>
                <ToggleSmall
                  isChecked={ele.is_enabled}
                  handleChange={() => handleToggle(ele.id, ele.is_enabled)}
                  disabled={!permCheck('all', 'Administration Features')}
                />
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};
