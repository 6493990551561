import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormContext,
  FormContextProvider,
  FormHelpText,
  FormSchemaFields,
} from '@sw-sw/lib-form';
import { LoadingIcon, Modal } from '@sw-sw/lib-ui';
import c from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import printApi from '../../../utils/api/print';
// import { printPDF } from '../../../utils/print';
import CheckmarkSVG from '../CheckmarkSVG';
import FormActions from '../form/modal/FormActions';
import AppContext from '../../../contexts/AppContext';

const getStatusIcon = (status) => {
  switch (status) {
    case 'error':
      return <FontAwesomeIcon icon='times' color='rgb(187, 28, 28)' />;

    case 'done':
    case 'connected':
      return <CheckmarkSVG />;

    default:
      return <LoadingIcon />;
  }
};

const getSubmitText = (showStatus, status) => {
  if (showStatus) {
    if (status === 'error') {
      return 'Retry';
    }

    return status;
  }

  return 'Print';
};
/**
 * Print Modal. Connects to API and initializes printing job
 *
 * @todo display form
 */
const PrintModalUI = ({
  onClose,
  formSchema,
  connected,
  status,
  statusMessage,
  downloadUrl,
  runJob,
  /** @deprecated */
  handleChange,
  jobId,
}) => {
  const formContext = useContext(FormContext);
  const [shouldRunJob, setShouldRunJob] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const appContext = useContext(AppContext)
  const user = appContext.get("user");

  /** run job, when connected and form is submitted */
  useEffect(() => {
    if (connected && shouldRunJob) {
      formContext.setBusy(true);
      setShowStatus(true);
      runJob(formContext.value);
    }
  }, [connected, shouldRunJob]);

  /** mock form submission if schema is missing */
  useEffect(() => {
    if (!formSchema) {
      setShouldRunJob(true);
    }
  }, []);

  /**
   * reset form on error
   */
  useEffect(() => {
    if (status === 'error') {
      formContext.setBusy(false);
      setShouldRunJob(false);
    }
  }, [status]);

  return (
    <div>
      {formSchema && (
        <FormSchemaFields
          formData={formContext.value}
          onChange={(key, val) => {
            formContext.set(key, val);
            if (handleChange) {
              handleChange(key, val, formContext).then(
                ({ shouldUpdate, updateKey, updateValue }) => {
                  if (shouldUpdate) {
                    formContext.set(updateKey, updateValue);
                    formContext.validate(updateKey);
                  }
                },
              );
            }
          }}
          schema={
            typeof formSchema === 'function'
              ? formSchema(formContext.value, showStatus, user.roleName)
              : formSchema
          }
        />
      )}

      <FormActions
        onSubmit={(data) => {
          setShouldRunJob(true);

          return data;
        }}
        onCancel={onClose}
        showCancel={['error', 'done'].includes(status)}
        showSubmit={status !== 'done'}
        submitText={getSubmitText(showStatus, status)}
        cancelText='Close'
      >
        {jobId ? (
          <div className='print-job-id'>
            <FormHelpText theme='info' content={`Job ID: ${jobId}`} />
          </div>
        ) : null}
        {showStatus && (
          <div className='print-status'>
            {getStatusIcon(status)}
            {statusMessage && (
              <FormHelpText
                theme={status === 'error' ? 'error' : 'info'}
                content={statusMessage}
              />
            )}
            {downloadUrl && status !== 'downloading' && (
              <p>
                &nbsp;
                &nbsp;
                <a
                  href={downloadUrl}
                  download
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Download
                </a>
              </p>
            )}
          </div>
        )}
      </FormActions>
    </div>
  );
};

PrintModalUI.propTypes = {
  formSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  connected: PropTypes.bool.isRequired,
  status: PropTypes.string,
  jobId: PropTypes.string,
  statusMessage: PropTypes.string,
  downloadUrl: PropTypes.string,
  runJob: PropTypes.func.isRequired,
  /** @deprecated */
  handleChange: PropTypes.func,
};

const PrintModal = ({
  onClose,
  jobName,
  jobData,
  initialFormData,
  modalTitle = 'Generate Report',
  validateData,
  statusUpdateKey,
  className = '',
  ...props
}) => {
  const [connected, setConnected] = useState(false);
  const [status, setStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [jobId, setJobId] = useState(null);

  const downloadCount = useRef(0);

  const closeAndCancelJob = () => {
    if(!statusMessage){
      onClose()
    }

    if(statusMessage && jobId){
      printApi.cancelJob(jobId)
      .then(() => onClose())
    }
    else{
      onClose()
    }
  }

  /** Initialize printing api */
  useEffect(() => {
    setStatus('connecting');

    printApi
      .init()
      .then((sk) => {
        console.log('init', sk);
        setStatus('connected');
        setConnected(true);
      })
      .catch((err) => {
        setConnected(false);
        setStatus('error');
        setStatusMessage(
          err.message || 'Unknown error establishing connection',
        );
      });

    return () => {
      printApi.deinit();
    };
  }, []);

  /** Download and/or show link, when complete */
  useEffect(() => {
    if (downloadUrl) {
      setStatusMessage('report successfully generated');

      // printPDF(downloadUrl, {
      //   showModal: false,
      //   onLoadingStart: () => {
      //     setStatus('downloading');
      //     setStatusMessage('the report will print momentarily');
      //   },

      //   onLoadingEnd: () => {
      //     setStatus('done');
      //     setStatusMessage(null);
      //   },

      //   onError: (err) => {
      //     setStatus('print error');
      //     setStatusMessage(err && err.message ? err.message : 'unknown error');
      //   },
      // });
    }
  }, [downloadUrl]);

  const runJob = (formData = {}) => {
    setStatus('requested');

    printApi.init().then(() =>
      printApi.run(
        typeof jobName === 'function' ? jobName(formData) : jobName,
        {
          ...jobData,
          ...(validateData ? validateData(formData) : formData),
        },
        (payload) => {
          if (payload.status === 'progress') downloadCount.current += 1;

          setStatus(payload.status);
          setStatusMessage(
            payload.status === 'progress'
              ? `building report: ${downloadCount.current}/${payload.total}`
              : payload.status === 'error'
                ? payload.message
                : payload.status,
          );

          if (payload.url) {
            setDownloadUrl(payload.url);
          }

          if (payload.jobId) {
            setJobId(payload.jobId);
          }
        },
        statusUpdateKey,
      ),
    );
  };

  if (!jobName || !jobData) {
    return null;
  }

  return (
    <FormContextProvider initialValue={initialFormData}>
      <Modal
        classes={c('print-modal', className)}
        show={true}
        handleClose={() => closeAndCancelJob()}
        hideButtons
        title={modalTitle}
        isSmall
      >
        <PrintModalUI
          {...props}
          connected={connected}
          status={status}
          statusMessage={statusMessage}
          downloadUrl={downloadUrl}
          onClose={() => closeAndCancelJob()}
          runJob={runJob}
          jobId={jobId}
        />
      </Modal>
    </FormContextProvider>
  );
};

PrintModal.propTypes = {
  jobName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  jobData: PropTypes.object.isRequired,
  statusUpdateKey: PropTypes.number,
  // optional. Form to fill-out, before running report
  formSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  initialFormData: PropTypes.any,
  handleChange: PropTypes.func, // returns Promise({ shouldUpdate, updateKey, updateValue })
  validateData: PropTypes.func,

  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  className: PropTypes.string,
};

PrintModal.defaultProps = {
  modalTitle: 'Generate Report',
  initialFormData: {},
  validateData: undefined,
};

export default PrintModal;
