import React, { useContext } from 'react';
import ProjectContext from '../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import RolesContext from '../../../contexts/RolesContext';
import DashboardContext from '../../../contexts/DashboardContext';
import { useProjectContacts } from '../../../hooks/projectDetails';
import projectApi from '../../../utils/api/project';
import Contacts from '../../Contacts/Contacts';
import Loading from '../../Shared/ResourceIndex/Loading';
import ContactAdd from '../Header/ContactAdd';

const ProjectContacts: React.FC = () => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const { inspectionsCertDue, fetchStatsFuncForMP } = useContext(DashboardContext);

  const context = useContext(ProjectContext);

  const project = context.project;

  const contactsQuery = useProjectContacts(project ? project.id : 0);

  if (!project || !project.id) {
    return null;
  }

  const deleteContact = (id: number, userId: number) => {
    return projectApi.contacts.destroy(id, userId).then(() => {
      contactsQuery.refetch();
      inspectionsCertDue.refetch();
      fetchStatsFuncForMP.refetch()
    });
  };

  if (!contactsQuery.data) {
    return <Loading what="contacts" />;
  }

  return (
    <div className="project-contacts">
      <React.Fragment>
        {!projectPermissionContext.readOnly && permCheck("update", "Project") && (
          <div className="project-contacts-actions">
            <ContactAdd
              currentContactIds={contactsQuery.data.map((_: any) => _.id)}
              onAdd={() => {
                contactsQuery.refetch();
                inspectionsCertDue.refetch();
                fetchStatsFuncForMP.refetch()
              }}
            />
          </div>
        )}

        <div className="project-contacts-list">
          <Contacts
            contacts={contactsQuery.data}
            onDelete={deleteContact}
            id={project.id}
            parent={project.name}
            label={"Project"}
            readOnly={projectPermissionContext.readOnly}
          />
        </div>
      </React.Fragment>
    </div>
  );
};

ProjectContacts.propTypes = {};

export default ProjectContacts;
