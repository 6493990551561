import React, {useState} from "react";
import { ActionButtons } from "../Shared/ActionButtons";
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Shared/ResourceIndex/Loading";
import IntervalModal from "./IntervalModal";
import { 
  FormContextProvider,
} from "@sw-sw/lib-form";
import inspectionIntervalsApi from "../../utils/api/inspectionIntervals";
import ToggleSmall from "../Shared/Toggle/ToggleSmall";

const InspectionIntervals = () => {
  const [collapse, setCollapse] = useState(false);
  const [showIntervalModal, setshowIntervalModal] = useState(false);
  const [isEditAction, setIsEditAction] = useState(false)
  const [selectedIntervalDetails, setSelectedIntervalDetails] = useState(null)
  const [toggleState, setToggleState] = useState<any>({})

  const inspectionIntervalsQuery = useQuery('inspectionIntervalsQuery', () => inspectionIntervalsApi.fetch(`/inspection-intervals`));

  const handleIncludeToggleOnChange = async(id:any, include: any) => {
    if(toggleState.hasOwnProperty(id)){
      setToggleState({ ...toggleState, [id]: !toggleState[`${id}`] })
      await inspectionIntervalsApi.updateIncludeValue(id, {include: !toggleState[`${id}`]})

      return
    }

    setToggleState({...toggleState, [id]: !include})
    await inspectionIntervalsApi.updateIncludeValue(id, {include: !include})
  }

  return (
    <div className="inspection-intervals">
      <div className='inspection-intervals__header' onClick={() => setCollapse(!collapse)}>
        <h3>Inspection Intervals</h3>
        <span>
          <i
            className={collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
            aria-hidden='true'
          />
        </span>
      </div>
      <hr></hr>
      <br />

      <div className={collapse ? 'hidden inspection-intervals__content' : 'visible inspection-intervals__content'}>
        <div className="inspection-intervals__table-head inspection-intervals__table-head">
          <h3>Name </h3>
          <h3>Interval</h3>
          <h3>Code</h3>
          <h3>Include</h3>
        </div>

        { !inspectionIntervalsQuery.isLoading ?
          inspectionIntervalsQuery.data && inspectionIntervalsQuery.data.data && inspectionIntervalsQuery.data.data.length && inspectionIntervalsQuery.data.data.map((ele:any) => {
            return(
              <div className="inspection-intervals__table-body">
                <h3>{ele.name}</h3>
                <h3>{ele.interval}</h3>
                <h3>{ele.code}</h3>
                <h3 className="inspection-intervals__include">
                  <div>
                    <span className="inspection-intervals__include-toggle">
                      <ToggleSmall
                        isChecked={toggleState.hasOwnProperty(ele.id) ? toggleState[`${ele.id}`] : ele.include}
                        handleChange={() => { handleIncludeToggleOnChange(ele.id, ele.include) }}
                      />
                    </span>
                    <span onClick={() => setshowIntervalModal(true)} className="inspection-intervals__include-edit">
                      <FontAwesomeIcon
                        icon={faEdit}
                        title="Edit Interval"
                        onClick={() => {
                          setshowIntervalModal(true)
                          setIsEditAction(true)
                          setSelectedIntervalDetails(ele)
                        }}
                      />
                    </span>
                  </div>
                </h3>
              </div>
            )
          })
          : <Loading what='data' />
        }

        <ActionButtons
          className="inspection-intervals__create-new-button"
          primary='Create New Interval +'
          onClick={() => {
            setshowIntervalModal(true)
            setIsEditAction(false)
          }}
        />

        <br/><br/><br/>

        {showIntervalModal ? 
          <FormContextProvider>
            <IntervalModal setshowIntervalModal={setshowIntervalModal} isEditAction={isEditAction} selectedIntervalDetails={selectedIntervalDetails} inspectionIntervalsQuery={inspectionIntervalsQuery}/>
          </FormContextProvider> 
          : <></>
        }
      </div>
    </div>
  );
};

export default InspectionIntervals;
