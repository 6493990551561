import { FormContext, FormContextProvider } from '@sw-sw/lib-form';
import { VersionContext } from '@sw-sw/lib-ui';
import { get, has, isNull } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '../../../contexts/AppContext';
import InspectionContext from '../../../contexts/InspectionContext';
import ProjectContext from '../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { VersionContextProvider } from '../../../contexts/VersionContext';
import { useFindingActions } from '../../../hooks/finding';
import findingApi from '../../../utils/api/finding';
import { ActionButtonsWithScrollFix } from '../../Shared/ActionButtons';
import ToastError from '../../Shared/ToastError/ToastError';
import DeleteFindingModal from './DeleteFindingModal';
import FindingsForm, {
  extractFindingData,
  toImageUpload,
} from './FindingsForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import FindingReopenConfirmationModal from './FindingReopenConfirmationModal';
import DashboardContext from '../../../contexts/DashboardContext';

function FindingDetailFormUI({
  onBackClick,
  inspectionId,
  finding,
  onSubmit,
  onDelete,
  findingNumber,

  ...props
}) {
  const formContext = useContext(FormContext);
  const inspectionContext = useContext(InspectionContext);
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const [findingId, setFindingId] = useState(finding.id);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const inspectionComplete = inspectionContext.readOnlyFindings;
  const [isCompleted, setIsCompleted] = useState(
    props.isCompleted || inspectionComplete,
  );
  const [isFindingsDateCompleted, setIsFindingsDateCompleted] = useState(props.isFindingsDateCompleted);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showReopenConfirmationModal, setShowReopenConfirmationModal] =
    useState(false);
  const findingActions = useFindingActions({
    onDelete: () => setConfirmDelete(true),
    edit: true,
  });
  const history = useHistory();
  const { findings, inspection } = inspectionContext;
  const {
    date_completed: dateCompleted,
  } = formContext.value;

  const isPublic = window.location.pathname.startsWith("/public")

  useEffect(() => {
    setIsCompleted(props.isCompleted || inspectionComplete)
    setIsFindingsDateCompleted(props.isFindingsDateCompleted)
  },[props.isCompleted, props.isFindingsDateCompleted])

  function handleSubmit() {
    formContext.setBusy(true);

    const toastId = toast('Updating finding');

    return findingApi
      .update(finding.id, { ...formContext.value, inspectionId })
      .then((updatedFinding) => {
        // reset comments/observations
        const { observations } = extractFindingData(
          updatedFinding,
          inspectionId,
        );

        formContext.set('comments', '');
        formContext.set('observations', observations);

        // set images from api
        formContext.set(
          'images',
          updatedFinding.finding_uploads.filter(image=>!image.is_completed).map(toImageUpload),
        );

        if(updatedFinding.finding_uploads.filter(image=>image.is_completed).length > 0)
        formContext.set(
          'completedImages',
          updatedFinding.finding_uploads.filter(image=>image.is_completed).map(toImageUpload),
        )

        // update context
        const { setFindings } = inspectionContext;

        setFindings(
          findings.map((f) =>
            f.id === updatedFinding.id ? updatedFinding : f,
          ),
        );

        setIsCompleted(!isNull(updatedFinding.date_completed) || !isNull(inspection.compliance_date));
        setIsFindingsDateCompleted(!isNull(updatedFinding.date_completed))
        formContext.setBusy(false);

        toast.update(toastId, {
          render: 'Updated Finding',
          type: 'success',
        });

        return onSubmit(updatedFinding);
      })
      .catch(async (error) => {
        formContext.setBusy(false);

        let toastMessage = '';

        if (has(error, 'response.data.message')) {
          toastMessage = error.response.data.message;
        } else if (has(error, 'response.data')) {
          toastMessage = error.response.data;
        } else {
          toastMessage =
            'Cannot save finding due to an unknown error. Please try again later.';
        }

        toast.update(toastId, {
          render: (
            <ToastError
              message='Cannot update finding because of error: '
              error={toastMessage}
            />
          ),
          autoClose: false,
          type: 'error',
        });
        setIsCompleted(true);
        setIsFindingsDateCompleted(true)
        inspectionContext.reloadInspection();
      });
  }

  function handleEdit() {
    findings.forEach((find) => {
      if (find.number === findingNumber) {
        if (find.is_required_for_cert) {
          setShowReopenConfirmationModal(true);
        } else {
          setIsCompleted(false);
          setIsFindingsDateCompleted(false)
        }
      }
    })
  }

  function editText() {
    return permCheck('all', 'reopenFinding') || (permCheck("update", "Close Findings") && !dateCompleted) ?
      <React.Fragment>
        <FontAwesomeIcon icon={faPencilAlt} /> Edit Finding
      </React.Fragment> :
      null;
  }

  const extraButtonText = () => {
    return isFindingsDateCompleted ? 'Edit' : 'Save'
  }

  const handleExtraButtonClick = () => {
    switch(isFindingsDateCompleted){
      case true : 
        findings.forEach((find) => {
          if (find.number === findingNumber) {
            if (find.is_required_for_cert) {
              setShowReopenConfirmationModal(true);
            } else {
              setIsFindingsDateCompleted(false);
            }
          }
        })
        break

      case false : 
        handleSubmit()
        break

      default: break
    }    
  }

  useEffect(() => {
    const findingIds = findings.map((ele) => {
      return ele.id
    })

    if (findingIds[0] === findingId) {
      setDisablePrev(true);
    }
    if (findingIds[findingIds.length - 1] === findingId) {
      setDisableNext(true);
    }

  }, []);

  const findingIds = findings.map((ele) => {
    return ele.id
  })

  const getNextFinding = (id) => {
    const currentPosition = findingIds.indexOf(findingId)

    if (currentPosition !== findings.length - 1) {
      setFindingId(findingIds[currentPosition + 1]);
      setRedirect(true);
    }
  };

  const getPrevFinding = (id) => {
    const currentPosition = findingIds.indexOf(findingId)

    if (currentPosition !== 0) {
      setFindingId(findingIds[currentPosition - 1]);
      setRedirect(true);
    }
  };

  if (redirect) {

    window.location.href = isPublic ? `/public/inspection/${inspectionId}/findings/${findingId}/details` : `/inspection/${inspectionId}/findings/${findingId}/details`
  }

  return (
    <section className='finding-detail container'>
      <ActionButtonsWithScrollFix
        primary={isCompleted ? editText() : findingActions.primary}
        primaryDisabled={!formContext.isValid}
        onClick={isCompleted ? handleEdit : handleSubmit}
        secondary={findingActions.secondary}
        busy={showReopenConfirmationModal || formContext.isBusy}
        spacer={false}
        extraButton={(isFindingsDateCompleted && isCompleted) || (!isFindingsDateCompleted && !isCompleted) ? false : true}
        extraButtonText={extraButtonText}
        handleExtraButtonClick={handleExtraButtonClick}
      />
      <div className='controls'>
        <h5 className='finding-link'>
          <span
            className='pointer'
            onClick={() => isPublic ? history.push(`/public/inspection/${inspectionId}/findings`) : history.push(`/inspection/${inspectionId}/findings`) }
          >
            <i className='fa fa-caret-left pad-right' />
            All Findings
          </span>
        </h5>
        <h3>Overview for #{findingNumber}</h3>
      </div>

      <FindingsForm
        templateName={get(projectContext, 'template.name')}
        readOnly={
          findingActions.primary === null ||
          projectPermissionContext.readOnly 
          || isCompleted
        }
        disableFieldRelatedToDateCompleted={
          findingActions.primary === null ||
          projectPermissionContext.readOnly 
          || isFindingsDateCompleted
        }
        defaultObservation={get(
          projectContext,
          'project.client.default_finding_observation',
          '',
        )}
        inspectionId={parseInt(inspectionId)}
        templateID={get(projectContext, 'template.id')}
        isCompleted={isCompleted}
      />

      <DeleteFindingModal
        show={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        onDelete={onDelete}
        findingId={finding.id}
        inspectionId={inspectionId}
      />
      {showReopenConfirmationModal && (
        <FindingReopenConfirmationModal
          onClose={() => {
            setShowReopenConfirmationModal(false);
          }}
          findingId={finding.id}
          onSuccess={() => {
            formContext.set('date_completed', null);
            inspectionContext.reloadInspection();
            setIsCompleted(false);
          }}
        />
      )}
      <div className='center next-previous-button-height'>
        <button
          className='button-outline-dark'
          onClick={() => getPrevFinding()}
          disabled={disablePrev}
        >
          <i className='fa fa-angle-left pad-right' />
          Previous
        </button>{' '}
        <button
          className='button-outline-dark'
          onClick={() => getNextFinding()}
          disabled={disableNext}
        >
          Next
          <i className='fa fa-angle-right pad-left' />
        </button>
      </div>
    </section>
  );
}

/**
 *
 * @param {{getFindings: Function, setFinding: Function}} dependencies
 */
const makeGetFindingApi =
  ({ getFindings, setFinding }) =>
    async (findingsId) => {
      const findings = await getFindings(findingsId);

      setFinding(findings);
    };

/**
 *
 * @param {{ extractFindingData: Function, setInitialValue: Function }} dependencies
 */
const makeSetFormData =
  ({ extractFindingData: extractFindingDataProp, setInitialValue }) =>
    (finding, inspectionId) => {
      const initialValue = extractFindingDataProp(finding, inspectionId);

      setInitialValue(initialValue);
    };

const useFormData = (finding) => {
  const [initialValue, setInitialValue] = useState(null);
  const setFormData = makeSetFormData({
    extractFindingData,
    setInitialValue,
  });

  useEffect(() => {
    if (!finding) {
      return;
    }

    setFormData(finding);
  }, [finding]);

  return initialValue;
};

const useGetFindingApi = (findingsId) => {
  const [finding, setFinding] = useState(null);
  const appContext = useContext(AppContext);
  const getFindings = makeGetFindingApi({
    getFindings: findingApi.show,
    setFinding,
  });

  useEffect(() => {
    appContext.loadData(() => getFindings(findingsId), 'Finding');
  }, []);

  return finding;
};

function FindingDetail({ onBackClick, onSubmit, onDelete }) {
  const { userHasPermission } = useContext(RolesContext);
  const { inspection } = useContext(InspectionContext);
  const { inspectionsCertDue, fetchStatsFuncForOF } = useContext(DashboardContext);
  const match = useRouteMatch();
  const finding = useGetFindingApi(match.params.findingId);
  const initialValue = useFormData(finding);

  if (!initialValue || !finding) {
    return null;
  }
  
  return (
    <VersionContextProvider>
      <FormContextProvider initialValue={initialValue}>
        <VersionContext.Consumer>
          {(versionContext) => (
            <FindingDetailFormUI
              inspectionId={match.params.inspectionId}
              finding={finding}
              onBackClick={onBackClick}
              onSubmit={() => {
                versionContext.resetDate();
                inspectionsCertDue.refetch();
                fetchStatsFuncForOF.refetch();
                onSubmit();
              }}
              onDelete={onDelete}
              permCheck={userHasPermission}
              isCompleted={
                !isNull(initialValue.date_completed) ||
                !isNull(inspection.compliance_date)
              }
              isFindingsDateCompleted={
                !isNull(initialValue.date_completed)
              }
              findingNumber={finding.number}
            />
          )}
        </VersionContext.Consumer>
      </FormContextProvider>
    </VersionContextProvider>
  );
}

export default FindingDetail;
