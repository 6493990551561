import {
  CertificationIndexResponse,
  CertSchemaField,
  CreateApiParam,
  fieldKeyCodec,
  getInitialFormData,
  SignatureGroupType,
} from '@sw-sw/lib-certification';
import { FormContextProvider } from '@sw-sw/lib-form';
import { get, set } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import LoadingModal from "../components/Shared/Loading/LoadingModal";
import { Loading } from "../components/Shared/ResourceIndex/Loading";
import SuccessNotification from "../components/Shared/SuccessNotification/SuccessNotification";
import { certificationApi } from "../utils/api/certification";
import AppContext from "./AppContext";
import DashboardContext from "./DashboardContext";
import { InspectionContext } from "./InspectionContext";
import NotificationStore from '../contexts/NotificationsContext'
import { ProjectContext } from '../contexts/ProjectContext'

type CertificationContextValue = {
  // loading: boolean;
  error?: string;
  state: CertificationIndexResponse;
  onSave: SaveHandler;
  // setShowModal: Dispatch<ShowModalState>;
  flashSuccess: () => void;
};

type ShowModalState = null | 'loading' | 'success';
type SaveHandler = (
  arg: Pick<CreateApiParam, 'lineType' | 'group'> & {
    formData: any;
  },
) => Promise<void>;

const InspectionCertificationContext =
  React.createContext<CertificationContextValue>({
    // loading: true,
    onSave: async () => { },
    flashSuccess: () => { },
    state: {} as CertificationIndexResponse,
  });

export const InspectionCertificationContextProvider: React.FC<
  PropsWithChildren<{}>
> = ({ children }) => {
  const inspectionContext = useContext(InspectionContext);
  const projectContext = useContext(ProjectContext);  
  const {notificationSettings} = useContext(NotificationStore)  
  const { inspectionsCertDue, fetchStatsFuncForID } = useContext(DashboardContext)
  const appContext = useContext(AppContext);
  const { id: inspectionId } = inspectionContext.inspection;
  const query = useQuery({
    queryKey: ['cert', inspectionId],
    queryFn: () => certificationApi.index(inspectionId),
    refetchOnMount: false,
    refetchOnWindowFocus: true,
  });
  const [showModal, setShowModal] = useState<ShowModalState>(null);

  const isFindingRequired = () => {
    let isReq = false

    inspectionContext.findings.length && inspectionContext.findings.map((findings: any) => {
      if (findings.is_required_for_cert) {
        isReq = true
      }
    })

    return isReq
  }

  const latestFindingClosureDate = () => {
    const doi = new Date(inspectionContext.inspection.created_date)
    const doiWithTz = new Date(doi.getTime() + doi.getTimezoneOffset() * 60000)

    const dates = inspectionContext.findings
      .filter((ele: any) => ele.is_required_for_cert)
      .filter(((ele: any) => ele.date_completed))
      .map((ele: any) => new Date(ele.date_completed))

    if (!dates.length) return null

    if (dates.length === 1) {
      return dates[0] > doiWithTz ? dates[0] : doiWithTz
    }

    if (dates.length >= 2) {
      let maxdate = dates.sort(function (d1: any, d2: any) {
        return d2 - d1;
      })[0]

      return maxdate > doiWithTz ? maxdate : doiWithTz
    }
  }

  const inspectorDefaultDate = () => {
    const date = new Date(inspectionContext.inspection.created_date);
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + offset);
  };

  const complianceDefaultDate = () => {

    const doi = new Date(inspectionContext.inspection.created_date)
    const doiWithTz = new Date(doi.getTime() + doi.getTimezoneOffset() * 60000)

    if (query.data && query.data.signatureGroups && query.data.signatureGroups.compliance.length) {
      if (query.data.signatureGroups.compliance[0].defaultDateLogic === 'doi'){
        return (isFindingRequired() && latestFindingClosureDate()) ? latestFindingClosureDate() : doiWithTz
      }
      else
        return new Date()
    }
    else
      return new Date()
  }

  const acknowledgementDefaultDate = () => {
    const doi = new Date(inspectionContext.inspection.created_date);
    const doiWithTz = new Date(doi.getTime() + doi.getTimezoneOffset() * 60000)

    if (query.data && query.data.signatureGroups && query.data.signatureGroups.acknowledgement.length) {
      if (query.data.signatureGroups.acknowledgement[0].defaultDateLogic === 'doi' || (projectContext.template && projectContext.template.name === 'CDOT')) 
      return doiWithTz
      else
        return new Date()
    }
    else
      return new Date()
  }

  const initialFormData = useMemo(() => {
    if (query.data) {
      const { default_email_message: emailMessage = '' } =
        appContext.getNotificationSetting('Inspection Complete') || {};

      const initialData = getInitialFormData({
        ...query.data,
        user: {
          ...appContext.get('user'),
          emailMessage,
        },
        // for inspection signatures
        inspectionDate: inspectorDefaultDate(),
        complianceDate: complianceDefaultDate(),
        acknowledgementDate: acknowledgementDefaultDate(),
      });

      return Object.keys(initialData).reduce((data, key) => {
        /** @important, use lodash.set, to ensure compatibility with context */
        set(data, key, initialData[key]);

        return data;
      }, {});
    }

    return {};
  }, [query.data]);

  const flashSuccessModal = () => {
    window.setTimeout(() => setShowModal(null), 2000);
    setShowModal('success');
  };

  const onSave: SaveHandler = useCallback(
    async ({ group, lineType, formData }) => {
      setShowModal('loading');

      const data: CreateApiParam = {
        inspectionId,
        group,
        lineType,
        date: get(
          formData,
          fieldKeyCodec.encode(lineType, CertSchemaField.date),
        ),
      };
      
      const getDefaultMsg  = notificationSettings.filter((e: { name: any; }) => e.name === "Inspection Complete")[0].default_email_message

      if (group === SignatureGroupType.inspection) {
        data.emailMessage = get(formData, CertSchemaField.emailMessage);
        data.default_message = getDefaultMsg
      }
      
      return certificationApi
        .create(data)
        .then(async () => {
          flashSuccessModal();
          inspectionsCertDue.refetch()
          fetchStatsFuncForID.refetch()
          query.refetch();
          // lock inspection questions after compliance signature
          if (data.lineType === SignatureGroupType.compliance) {
            inspectionContext.updateInspection(
              Object.assign(inspectionContext.inspection, {
                compliance_date: data.date,
              }),
            );
          }
        })
        .catch((err) => {
          setShowModal(null);

          return Promise.reject(err);
        });
    },
    [inspectionId, query.data],
  );

  useEffect(
    () => () => {
      query.remove();
    },
    [],
  );

  if (query.isLoading || query.data === undefined) {
    return <Loading />;
  }

  return (
    <InspectionCertificationContext.Provider
      value={{
        // loading: query.isLoading,
        error: String(query.error) || undefined,
        state: query.data,
        onSave,
        flashSuccess: flashSuccessModal,
      }}
    >
      <FormContextProvider initialValue={initialFormData}>
        {children}
      </FormContextProvider>

      <SuccessNotification show={showModal === 'success'} />
      <LoadingModal show={showModal === 'loading'} />
    </InspectionCertificationContext.Provider>
  );
};

export default InspectionCertificationContext;
