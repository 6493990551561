import { debounce, get, has, noop } from 'lodash';
import {  useEffect, useRef, useState } from 'react';
import {
  QueryObserverOptions,
  QueryObserverResult,
  useQuery,
} from 'react-query';
import { useHistory } from "react-router-dom";
import {
  PaginationContextValue,
  PaginationState,
} from '../contexts/PaginationContext';
import apiResources from '../utils/apiResources';

export type QueryDefaults<D = any> = Omit<
  Partial<QueryObserverOptions<QueryResponse<D>>>,
  'refectchInterval'
> & {
  refetchInterval?: number;
};

export type ApiQueryProps<D extends IApiQueryResource = IApiQueryResource> = {
  resource: string;
  search?: string;
  divisionId?: number;
  queryDefaults?: QueryDefaults<D> | any;
  projectId?: number;
  pageSize?: number;
} & Pick<PaginationContextValue, 'setPage' | 'setPaginationState' | 'page' | 'setPageSize'>;

export interface IApiQueryResource {
  id: number;
  name: string;
}

export type QueryResponse<D extends any> =
  | D[]
  | { paginationState?: PaginationState; pageData?: D[] };

export function useApiQuery<D extends IApiQueryResource = IApiQueryResource>({
  resource,
  page: pageProp,
  search,
  setPage = noop,
  setPaginationState = noop,
  divisionId,
  queryDefaults = {},
  projectId,
  pageSize,
  setPageSize = noop
}: ApiQueryProps<D>): {
  query: QueryObserverResult<QueryResponse<D>>;
  data: D[] | null;
} {
  if (!has(apiResources, resource)) {
    throw new Error('invalid apiResource: apiResource.' + resource);
  }
  const page = useQueryPage({ divisionId, pageProp });
  const keyParts = getKeyParts({
    resource,
    divisionId,
    page: page || null,
    search,
    projectId
  });
  const history = useHistory()

  const key = useQueryKeyDebounced(...keyParts);

  const queryResults = useQuery(
    key,
    () =>
      get(
        apiResources,
        resource,
      )({
        divisionId,
        page: page || undefined,
        search: search,
        projectId: projectId,
        pageSize: pageSize,
      }) as Promise<QueryResponse<D>>,
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: true,
      ...queryDefaults,
    },
  );

  useEffect(() => {    
    
    if (queryResults.data) {
      if (
        !Array.isArray(queryResults.data) &&
        queryResults.data.paginationState
      ) {
        setPaginationState(queryResults.data.paginationState);
      }
    }
  }, [queryResults]);

  const getPageParam = history.location.search.split('page=')[1];

  useEffect(() => {
    if (page) {
      setPage(Number(getPageParam));
    }
  }, [getPageParam, divisionId]);

  useEffect(() => {
    if(pageSize){            
      setPageSize(pageSize)
    }
  }, [pageSize])
  
  return {
    query: queryResults,
    data: extractData(queryResults.data),
  };
}

function useQueryPage({
  divisionId,
  pageProp,
}: {
  divisionId?: number;
  pageProp?: number | null;
}) {
  let page = pageProp;
  const prevDivisionId = useRef(divisionId);

  /** @important - used to avoid extra api requests, when switching division */
  if (
    divisionId &&
    prevDivisionId.current &&
    prevDivisionId.current !== divisionId
  ) {
    prevDivisionId.current = divisionId;
    page = 1;
  }

  return page;
}

/**
 * Hook to maintain a query key
 *
 * @important debounce is used to avoid multiple queries, due to frequently-updating keyParts
 */
function useQueryKeyDebounced(...keyParts: (string | number)[]): string {
  const [key, updateKey] = useState(makeQueryKey(...keyParts));
  const updateKeyRef = useRef(debounce(updateQueryKey, 120));

  useEffect(() => {
    updateKeyRef.current({
      key: keyParts,
      updateKey,
    });

    return () => updateKeyRef.current.cancel();
  });

  return key;
}

function updateQueryKey({
  key,
  updateKey,
}: {
  key: (string | number)[];
  updateKey(key: string): void;
}): void {
  updateKey(makeQueryKey(...key));
}

function makeQueryKey(...parts: (string | number)[]) {
  return parts.join('_');
}

function getKeyParts({
  resource,
  divisionId,
  page,
  search = '',
  projectId
}: Omit<ApiQueryProps, 'setPage' | 'setPaginationState' | 'setPageSize'> & {
  divisionId?: number;
  projectId?: number;
}) {
  const parts = [resource];

  if (divisionId) {
    parts.push(divisionId.toString());
  }

  /** @important to support APIs without pagination & search, check before pushing these key parts */
  if (page) {
    parts.push(page.toString());

    if (search) {
      parts.push(search);
    }
  }

  if (!page && search) {
    parts.push(search);
  }

  if (projectId) {
    parts.push(projectId.toString());
  }

  return parts;
}

export function extractData<D = any>(response?: QueryResponse<D>): D[] | null {
  if (!response) {
    return null;
  }

  if (Array.isArray(response)) {
    return response;
  }
  if (response.paginationState && response.pageData) {
    return response.pageData;
  }
  throw new Error('Cannot handle response: ' + response);
}
