import React, { useState } from "react";
import { isEmpty, values } from "lodash";
// import { Changelog } from "@sw-sw/common";
import { useProjectLog } from "../../../hooks/projectDetails";
import Loading from "../../Shared/ResourceIndex/Loading";
import ChangeLog from "../Details/ChangeLog";
import PrecipitationLog from "./PrecipitationLog/PrecipitationLog";
import Select from "react-select";
import { FormContextProvider } from "@sw-sw/lib-form";

export interface IProjectLogProps {
  projectId: number;
}

const ProjectLog: React.FC<IProjectLogProps> = ({ projectId }) => {
  const projectLogQuery = useProjectLog(projectId);
  const [isPrecipitationLog, setIsPrecipitationLog] = useState(false)

  const logTabOptions = [
    {value: 1, label: 'Project Log'},
    {value: 2, label: 'Precipitation Log'},
  ]

  const onChangeFunc = (data:any) => {
    switch(data && data.value){
      case 1: setIsPrecipitationLog(false); break;

      case 2: setIsPrecipitationLog(true); break;

      default: setIsPrecipitationLog(false)
    }
  }

  if (!projectLogQuery.data) {
    return <Loading what="Project Log" />;
  }

  if (values(projectLogQuery.data).every(value => isEmpty(value))) {
    return <p>There are no logs for this project</p>;
  }

  return (
    <section className="project-log">
      <div className="project-log__select-wrapper">
        <Select
          closeMenuOnSelect={true}
          defaultValue={logTabOptions[0]}
          options={logTabOptions}
          onChange={(data) => { onChangeFunc(data) }}
          isClearable={false}
          className='project-log__select'
          classNamePrefix='project-log'
        />
      </div>
      <div>
        {
          isPrecipitationLog ? 
          <FormContextProvider>
            <PrecipitationLog/> 
          </FormContextProvider>
          : <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
        }
      </div>
    </section>
  );
};

export default ProjectLog;
